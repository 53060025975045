import React, { useState, useEffect } from "react";
import HeaderStrip from "../HeaderStrip/HeaderStrip";
import StudentStrip from "../StudentStrip/StudentStrip";
import WriteExam from "../WriteExam";
import ExamSelect from "../ExamSelect/ExamSelect";
import ALLURL from "../../../apiConfig";
import PageLoading from '../../../UI/PageLoading/PageLoading';

const ExamMain = ({ handleLogout }) => {
  const [studentData, setStudentData] = useState("");
  const [paperId, setPaperId] = useState("");
  const [isExamOn, setIsExamOn] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    const savedStudentData = localStorage.getItem("studentData");
    if (savedStudentData) {
      setStudentData(JSON.parse(savedStudentData));
    }
  }, []);

  const studentInfo = {
    name: studentData.name,
    regno: studentData.regNo,
    course: studentData.course,
    franchisee: studentData.fraName,
    code: studentData.fraCode,
  };

  const handleStartExam = async (paper) => {
    setisLoading(true);
    setPaperId(paperId);
    const { data } = await getQPData(studentData.studentID, paper);
    setisLoading(false);
    if (data.length > 0) {
      const sortedData = data.sort((a, b) => a.SlNo - b.SlNo);
      const convertedArray = sortedData.map((item) => convertData(item));
      localStorage.setItem("questionsData", JSON.stringify(convertedArray));
      setIsExamOn(true);
    } else {
      setIsExamOn(false);
    }
  };
  const handleSubmitExam = () => {
    setIsExamOn(false);
  };

  if (isLoading) {
    return <><PageLoading></PageLoading></>;
  } else {
    return (
      <>
        {isExamOn ? (
          <>
            <StudentStrip
              student={studentInfo}
              handleLogout={handleLogout}
            ></StudentStrip>
            <WriteExam onSubmitExam={handleSubmitExam}></WriteExam>
          </>
        ) : (
          <>
            <HeaderStrip student={studentInfo}></HeaderStrip>
            <StudentStrip
              student={studentInfo}
              handleLogout={handleLogout}
            ></StudentStrip>
            <ExamSelect handleStartExam={handleStartExam}></ExamSelect>
          </>
        )}
      </>
    );
  }
};
function convertData(data) {
  // Extract question
  const convertedData = {
    slno: data.SlNo,
    id: data.StudentAnswerID,
    question: data.Question,
    options: {
      OptA: data.OptA,
      OptB: data.OptB,
      OptC: data.OptC,
      OptD: data.OptD,
    },
    answer: "",
  };
  return convertedData;
}

async function getQPData(studentid, paperid) {
  try {
    const res = await fetch(
      ALLURL.api_url + "exam_OnlineExam/getqp/" + studentid + "/" + paperid
    );
    const data = await res.json();
    if (data) {
      return { data };
    } else {
    }
  } catch (error) {
    console.error("Error logging in:", error);
  }
}
export default ExamMain;
