import React, { useEffect, useState } from "react";
import { Container, Button, Form } from "react-bootstrap";
import Styles from "./WriteExam.module.css";
import APIURL from "../../apiConfig";

const WriteExam = ({ onSubmitExam }) => {
  const questions = JSON.parse(localStorage.getItem("questionsData"));
  const [timeLeft, setTimeLeft] = useState(
    () => parseInt(localStorage.getItem("timeLeft")) || questions.length * 60
  );
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState(
    () => JSON.parse(localStorage.getItem("answers")) || []
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        const updatedTime = prevTime - 1;
        localStorage.setItem("timeLeft", updatedTime.toString());
        return updatedTime;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      // Perform any actions when the time runs out
      console.log("Time is up!");
      onSubmitExam();
    }
  }, [onSubmitExam, timeLeft]);

  useEffect(() => {
    localStorage.setItem("answers", JSON.stringify(answers));
  }, [answers]);

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      submitQuestion(questions[currentQuestion].id, answers[currentQuestion]);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion < questions.length + 1) {
      setCurrentQuestion(currentQuestion - 1);
      submitQuestion(questions[currentQuestion].id, answers[currentQuestion]);
    }
  };

  const handleAnswerSelection = (selectedAnswer) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestion] = selectedAnswer;
    setAnswers(updatedAnswers);
  };

  const handleSubmitExam = () => {
    submitQuestion(questions[currentQuestion].id, answers[currentQuestion]);
    localStorage.removeItem("timeLeft");
    localStorage.removeItem("answers");
    onSubmitExam();
  };

  const submitQuestion = (questionId, answer) => {
    // Simulate API call to submit the question
    const Question = questions.find((q) => q.id === questionId);
    const ans =
      Question.options.OptA === answer
        ? "A"
        : Question.options.OptB === answer
        ? "B"
        : Question.options.OptC === answer
        ? "C"
        : Question.options.OptD === answer
        ? "D"
        : "";

    const timeLeft = localStorage.getItem("timeLeft");
    submitAnswer(questionId, ans, timeLeft, (error, result) => {
      if (error) {
        return;
      }
    });
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  const isNextButtonDisabled = !answers[currentQuestion];
  return (
    <Container className={Styles.container}>
      <div>
        <div className={Styles.timer}>
          <div className={Styles.timerHolder}>{formatTime(timeLeft)}</div>
        </div>
        <h5>
          {questions[currentQuestion].slno}.{" "}
          {questions[currentQuestion].question}
        </h5>
        <Form>
          {Object.entries(questions[currentQuestion].options).map(
            ([key, option], index) => {
              if (option === "") return null;
              return (
                <Form.Check
                  key={index}
                  type="radio"
                  name={`question_${currentQuestion}`}
                  value={option}
                  id={`option_${currentQuestion}_${index}`}
                  label={option}
                  checked={answers[currentQuestion] === option}
                  onChange={() => handleAnswerSelection(option)}
                  className={Styles.switch}
                />
              );
            }
          )}
        </Form>
      </div>
      <div>
        {/* {currentQuestion !== 0 && (
          <Button
            variant="primary"
            onClick={handlePreviousQuestion}
            className={`${Styles.button} ${Styles.prev}`}
          >
            Previous
          </Button>
        )} */}
        {currentQuestion !== questions.length - 1 && (
          <Button
            variant="primary"
            onClick={handleNextQuestion}
            disabled={isNextButtonDisabled}
            className={`${Styles.button} ${Styles.next}`}
          >
            Next
          </Button>
        )}
        {currentQuestion === questions.length - 1 && (
          <Button
            variant="success"
            disabled={isNextButtonDisabled}
            className={`${Styles.button} ${Styles.submit}`}
            onClick={handleSubmitExam}
          >
            Submit Exam
          </Button>
        )}
      </div>
    </Container>
  );
};
function submitAnswer(questionid, answer, timeLeft, callback) {
 
  fetch(
    APIURL.api_url +
      "exam_OnlineExam/submit/" +
      questionid +
      "/" +
      answer +
      "/" +
      timeLeft
  )
    .then((res) => res.json())
    .then((data) => {
      callback(null, { data });
    })
    .catch((error) => {
      console.error("Error logging in:", error);
      callback(error);
    });
}

export default WriteExam;
