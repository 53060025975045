import { useState, useEffect } from "react";
import Styles from "./ExamSelect.module.css";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios"; // if you're using axios
import APIURL from "../../../apiConfig";

const ExamSelect = ({ handleStartExam }) => {
  const [studentData, setStudentData] = useState("");
  useEffect(() => {
    const savedStudentData = localStorage.getItem("studentData");
    if (savedStudentData) {
      setStudentData(JSON.parse(savedStudentData));
    }
    //Call the API to Generate Question Paper
  }, []);
  useEffect(() => {
    generateQP(studentData.studentID);
  }, [studentData]);
  return (
    <>
      {studentData.papers ? (
        <Container>
          <Row>
            <Col sm={2}></Col>
            <Col sm={8}>
              {studentData.papers.map((exam, index) => (
                <Col sm={12} key={index}>
                  <div className={Styles.examCard}>
                    <Row>
                      <Col sm={4}>
                        <h5 className="mt-2">{exam.paperName}</h5>
                      </Col>
                      <Col sm={2}>
                        <p className={`mt-2 ${Styles.code}`}>{exam.code}</p>
                      </Col>
                      <Col sm={2}>
                        <p className={`${Styles.duration} mt-2`}>
                          {" "}
                          {exam.time}
                        </p>
                      </Col>
                      <Col sm={2}>
                        <p className={`${Styles.duration} mt-2`}>
                          {" "}
                          {exam.tMaxMarks} Questions
                        </p>
                      </Col>
                      <Col sm={2}>
                        <button
                          className={`btn btn-primary mt-2 ${Styles.button}`}
                          onClick={() => handleStartExam(exam.paperID)}
                        >
                          Start Exam
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Col>
            <Col sm={2}></Col>
          </Row>
        </Container>
      ) : (
        <>Unable to Retrive Exam List</>
      )}
    </>
  );
};

const generateQP = (studentid) => {
  axios
    .get(APIURL.api_url + "exam_OnlineExam/generateqp/" + studentid)
    .then((response) => {
      if (response.data) {
        // Do something with the data if it exists
      } else {
        // Do something if the data does not exist or is falsy
      }
    })
    .catch((error) => {
      console.error("Error Generating QP", error);
    });
};

export default ExamSelect;
