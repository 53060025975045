import React, { useEffect, useState } from "react";
import Styles from "./PageLoading.module.css";
import Logo from './logo-dark-strip.png';

const PageLoading = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 60000); 
    return () => clearTimeout(timer);
  }, []);

  const handleReload = () => {
    window.location.reload();
  }

  return (
    <>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="text-center">
          <img src={Logo} width={550} alt="Loading Logo" />
          <div className={Styles["main"]}>
            <div className={Styles["clock"]}>
              <div className={Styles["seconds"]}></div>
              <div className={Styles["minutes"]}></div>
            </div>
            {showButton && (
              <button onClick={handleReload} className={`btn btn-primary ${Styles["reload-button"]}`}>Reload Now</button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageLoading;
